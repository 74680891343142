<template>
	<Modal classes="modal--visual-content" class="modal--visual" @close="redirectBack">
		<template #header>
			<h2 class="h2">
				Please register to use <br />
				this resource
			</h2>
		</template>
		<template #content>
			<div>
				<VText size="small" classes="modal-section__card__content__subtitle">
          Registration is fast, simple and secure.
          Use your existing Google or LinkedIn
          account for one click access.
          Unsure? Read our <a href="/knowledgebase?activeFaq=59" target="_blank">knowledgebase</a> article here.
				</VText>
				<VButton
					color="primary"
					@click.stop="$store.commit('setActiveModal', 'RegisterModal')"
				>
					Register
				</VButton>
				<VButton
					color="primary"
					@click.stop="$store.commit('setActiveModal', 'LoginModal')"
				>
					Login
				</VButton>
			</div>
		</template>
	</Modal>
</template>

<script>
import modal from '@/mixins/modal';

export default {
	name: 'FreeAccountModal',
  mixins: [modal]
};
</script>
